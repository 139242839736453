<template>
  <div>
    <Modal v-model="modifyModal" :mask-closable="false" width="680" footer-hide>
      <p slot="header" class="text-center">{{ opType === 1 ? '新增' : '编辑' }}常规媒体产品</p>
      <Form ref="formValidate" :model="productModel" :rules="ruleValidate" :label-width="120">
        <Divider>基础信息</Divider>
        <FormItem label="所属资产" prop="assetIds">
          <Select v-model="productModel.assetIds" multiple size="small" placeholder="选择资产（可多选）">
            <Option v-for="item in assetArray" :key="'assetId_' + item.id" :value="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>

        <Row :gutter="8">
          <i-col span="12">
            <FormItem label="所属类目" prop="categoryId">
              <Select v-model="productModel.categoryId" size="small" placeholder="选择类目" @on-change="handleChangeCategory">
                <Option v-for="item in storageCategorys" :key="'category_' + item.id" :value="item.id">{{ item.name
                }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="所属产品" prop="productId">
              <Select v-model="productModel.productId" size="small" placeholder="选择产品">
                <Option v-for="item in productArray" :key="'product_' + item.id" :value="item.id">{{ item.name }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="8">
          <i-col span="12">
            <FormItem label="单品名称" prop="productskuName">
              <Input v-model="productModel.productskuName" size="small" placeholder="填写单品名称" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="站点等级">
              <Select v-model="productModel.level" size="small" placeholder="选择站点等级">
                <Option v-for="item in levelArray" :key="'product_' + item.value" :value="item.value">{{ item.name
                }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>

        <FormItem label="是否可用">
          <i-switch size="large" v-model="productModel.enabled">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>
          <Divider>售卖规则</Divider>
          <!--电子媒体-->
          <FormItem v-if="[8].includes(productModel.categoryId)" label="最小售卖规格（秒）" prop="duration">
            <InputNumber v-model="productModel.duration" :min="1" size="small" style="width:100%" placeholder="最小售卖规格" />
          </FormItem>
          <FormItem v-else label="最小售卖天数" prop="minday">
            <InputNumber v-model="productModel.minday" :min="1" size="small" style="width:100%" placeholder="最小售卖天数">
            </InputNumber>
          </FormItem>
          <Row :gutter="8">
            <i-col span="12">
              <FormItem v-show="[8].includes(productModel.categoryId)" label="每天周期数" prop="period">
                <InputNumber v-model="productModel.period" :min="1" size="small" style="width:100%" placeholder="每天周期数" />
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="起止时间" v-show="[8].includes(productModel.categoryId)">
                <TimePicker v-model="times" :editable="false" :transfer="true" size="small" type="timerange"
                  placeholder="选择起止时间" style="width: 100%"></TimePicker>
              </FormItem>
            </i-col>
          </Row>

        <Divider>媒介费配置</Divider>
        <Row :gutter="8">
          <i-col span="24">
            <FormItem label="媒介使用费" prop="usePrice">
              <InputNumber :min="0" v-model="productModel.usePrice"
                :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%" placeholder="输入媒介使用费">
              </InputNumber>
            </FormItem>
          </i-col>
          <!-- <i-col span="12">
            <FormItem label="代理商结算价" prop="statementPrice">
              <InputNumber :min="0" v-model="productModel.statementPrice"
                :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%"
                placeholder="输入代理商结算价">
              </InputNumber>
            </FormItem>
          </i-col> -->
        </Row>
        <FormItem class="text-right m-t-10">
          <Button type="text" class="m-r-5" @click="modifyModal = false">取消</Button>
          <Button type="primary" @click="handleSave">确定</Button>
        </FormItem>
      </Form>

    </Modal>
  </div>
</template>

<script>
import { productMixins } from '../mixins/product'

import { addCommon, editCommon } from '@/api/product/productskuedit'
export default {
  mixins: [productMixins],
  props: {
    saveSuccessFun: Function
  },
  data () {
    return {
      modifyModal: false,
      productModel: {
        assetIds: [],
        categoryId: null,
        desc: '',
        duration: 1,
        enabled: true,
        minday: 1,
        productId: null,
        productskuName: '',
        usePrice: 1,
        statementPrice: 0,
        endTime: '23:59:59',
        level: 1,
        period: 1,
        startTime: '00:00:00',
        cityCode: '',
        cityName: ''
      },
      times: [], // 起止时间
      productskuId: null, // 单品id
      companyInfo: {},
      ruleValidate: {
        assetIds: [
          { required: true, type: 'array', min: 1, message: ' ', trigger: 'change' }
        ],
        categoryId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        minday: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        duration: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        period: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        // duration: [
        //   { required: true, pattern: /^(\d+,?)+$/, message: ' ', trigger: 'blur' }
        // ],
        // period: [
        //   { required: true, pattern: /^(\d+,?)+$/, message: ' ', trigger: 'blur' }
        // ],
        productId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        productskuName: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        usePrice: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        statementPrice: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ]
      },
      curProduct: {},
      productArray: [],
      levelArray: [
        { value: -1, name: '全部' },
        { value: 1, name: 'A' },
        { value: 2, name: 'A+' },
        { value: 3, name: 'A++' },
        { value: 4, name: 'S' },
        { value: 5, name: 'S+' },
        { value: 6, name: 'S++' }
      ]
    }
  },
  computed: {
    assetArray () {
      return this.$store.state.productManage.assetArray
    },
    storageCategorys () {
      return this.$store.state.productManage.categoryArray
    },
    storageProducts () {
      return this.$store.state.productManage.productArray
    },
    opType () {
      return this.$store.state.productManage.opType
    }
  },
  mounted () {
    this.getCompanyInfo()
    // this.getCategoryData()
  },
  methods: {
    initShow (data) {
      if (this.opType === 1) {
        // 新增
        this.productskuId = null
        Object.assign(this.$data.productModel, this.$options.data().productModel)
        this.$refs.formValidate.resetFields()
      } else {
        // 编辑
        if (data) {
          this.curProduct = data
          // 先获取产品数据
          this.getProductData({ categoryId: data.categoryId }).then(res => {
            // 防止赋值不成功
            this.productModel.productId = data.productId
          })
          this.productModel.assetIds = data.assetIdList
          this.productModel.categoryId = data.categoryId
          this.productModel.desc = data.desc
          this.productModel.duration = Number(data.duration)
          this.productModel.enabled = data.enabled
          this.productModel.minday = data.minday
          this.productModel.productskuName = data.name
          this.productModel.usePrice = data.usePrice
          this.productModel.statementPrice = data.statementPrice
          this.productModel.endTime = data.endTime
          this.productModel.level = data.level
          this.productModel.period = Number(data.period)
          this.productModel.startTime = data.startTime
          this.productModel.cityCode = data.cityCode
          this.productModel.cityName = data.cityName
          this.productskuId = data.id
        }
      }
      this.times = [this.productModel.startTime, this.productModel.endTime]
      this.modifyModal = true
    },
    // handleChangeAsset () {
    //   // this.productModel.productId = null
    //   this.getResourceTypeData()
    // },
    handleChangeCategory () {
      this.productModel.productId = this.opType === 1 ? null : this.curProduct.productId
      if (this.productModel.categoryId) {
        this.getProductData({ categoryId: this.productModel.categoryId }).then(res => {
          if (!res || res.length === 0) {
            this.productModel.productId = null
          }
        })
      } else {
        this.productArray = []
      }
    },
    handleSave () {
      this.$refs.formValidate.validate(valid => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确定信息无误，并提交？',
            onOk: () => {
              this.productModel.assetIds = JSON.stringify(this.productModel.assetIds)
              // // 列车与其他类目处理方式
              // this.productModel.produceCost = this.productModel.categoryId === 2 ? this.productModel.produceCost : null

              if ([8].includes(this.productModel.categoryId)) {
                // // 去除电子媒体售卖规格和周期数最后的逗号（若存在）
                // const reg = /,$/gi
                // this.productModel.duration = this.productModel.duration.replace(reg, '')
                // this.productModel.period = this.productModel.period.replace(reg, '')

                // 电子媒体处理方式
                this.productModel.startTime = this.times[0]
                this.productModel.endTime = this.times[1]
              }
              if (this.opType === 1) {
                // 新增
                this.productModel.cityCode = this.companyInfo.cityCode
                this.productModel.cityName = this.companyInfo.cityName

                addCommon(this.productModel).then(res => {
                  this.modifyModal = false
                  if (res > 0) {
                    this.productskuId = res
                    this.$Notice.success({ desc: '操作成功' })
                    this.saveSuccessFun()
                  }
                })
              } else {
                const postData = Object.assign(this.productModel, { productskuId: this.productskuId })
                // 编辑
                editCommon(postData).then(res => {
                  this.modifyModal = false
                  if (res && res.errcode === 0) {
                    this.$Notice.success({ desc: '操作成功' })
                    this.saveSuccessFun()
                  }
                })
              }
            }
          })
        }
      })
    }

  }
}
</script>
